import { Modal } from "react-bootstrap";

interface ForceChangeModalProps {
  isOpen: boolean;
  onLogout: () => void;
  onSubmit: () => void;
}

export const ForceChangeModal = ({
  isOpen,
  onLogout,
  onSubmit,
}: ForceChangeModalProps) => {
  return (
    <Modal show={isOpen} centered animation={false}>
      <Modal.Header className="justify-content-center">
        <Modal.Title className="font-weight-semibold">
          Uwaga! Zmiana kategorii!
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="d-flex flex-column align-items-center">
        <div className="mb-3">
          <i
            className="fas fa-exclamation-triangle text-warning"
            style={{ fontSize: "5rem" }}
          />
        </div>
        <p className="text-center">
          Skrutiner zdecydował o zmianie kategorii na:
        </p>
        <p className="text-center mb-3">
          <strong>Solo - Debiuty (pow. 16 lat)</strong>
        </p>
        <p className="text-center mb-3">
          Dotychczasowa kategoria została zamknięta i zostanie otwarta później.
          Przejdź do nowej kategorii, jeśli chcesz ją sędziować, lub wyloguj się
          z panelu.
        </p>
        <div>
          <div className="d-flex flex-column">
            <button
              type="button"
              className="btn btn-indigo mb-2"
              onClick={() => onSubmit()}
            >
              Rozumiem, pokaż kategorię
            </button>
            <button
              type="button"
              onClick={() => onLogout()}
              className="btn btn-indigo-100 text-indigo mb-2"
            >
              Wyloguj
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
