import socket from "../socket";

export type SocketEventName = "connect" | "push_state";

export const connect = (
  jurySid: string,
  events: Record<SocketEventName, (data: any) => void>
) => {
  const names = Object.keys(events);

  names.map((name) => {
    // @ts-ignore
    socket.on(name, (data) => events[name](data));
  });
  socket.onAny((event, data) => console.log(event, data));

  socket.auth = { jurySid };
  socket.connect();

  return () => {
    names.map((name) => socket.off(name));
    socket.offAny();
  };
};
