const SESSION_KEY = "contesty:userSid";

export const getUserSid = () => {
  return sessionStorage.getItem(SESSION_KEY);
};

export const setUserSid = (userSid: string) => {
  sessionStorage.setItem(SESSION_KEY, userSid);
};

export const isAuthenticated = () => {
  return !!getUserSid();
};
