import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Login } from "./pages/login";
import { Competition } from "./pages/competition";
import { ProtectedPage } from "./components/protected-page/ProtectedPage";

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/competition"
          element={
            <ProtectedPage>
              <Competition />
            </ProtectedPage>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/competition" replace />} />
      </Routes>
    </>
  );
}

export default App;
