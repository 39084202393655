import { AuthUtils } from "./index";
import config from "../config";

const URL_BASE = `${config.backendUrl}/juries`;

const mapResponse = async (response: Response) => {
  if (!response.ok) {
    throw Error(JSON.stringify(await response.json()));
  } else {
    return response.json();
  }
};

export const get = <T>(url: string): Promise<T> => {
  const userSid = AuthUtils.getUserSid() || "";
  const headers = {
    sid: userSid,
  };
  return fetch(`${URL_BASE}${url}`, { method: "GET", headers }).then(
    mapResponse
  );
};

export const post = <T>(url: string, data: any): Promise<T> => {
  const headers = {
    "Content-Type": "application/json",
  };

  return fetch(`${URL_BASE}${url}`, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  }).then(mapResponse);
};
