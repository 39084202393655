interface SuccessfulSectionProps {
  onContinue: () => void;
  onLogout: () => void;
}

export const SuccessfulSection = ({
  onContinue,
  onLogout,
}: SuccessfulSectionProps) => {
  return (
    <div className="card" style={{ minHeight: "70vh" }}>
      <div className="card-body d-flex flex-column justify-content-center align-items-center">
        <img
          src="/img/done.png"
          alt="awaiting"
          className="mb-4"
          style={{ width: "300px", maxWidth: "calc(100vw - 30px)" }}
        />

        <div className="mb-4 text-center">
          <h5 className="font-weight-semibold">Dziękujemy, Mariusz.</h5>
          {/*<h5 className="font-weight-semibold">Wszystkie kategorie są w tej chwili zamknięte.</h5>*/}
          {/*<p className="mb-4">Czekaj na otwarcie kategorii...</p>*/}
          <p className="mb-0">Twoje noty zostały wysłane.</p>
        </div>
        <div>
          <div className="d-flex flex-column">
            <button
              className="btn btn-indigo-100 text-indigo mb-2"
              type="button"
              onClick={() => onContinue()}
            >
              Idź do następnej kategorii
            </button>
            <button
              className="btn btn-indigo-100 text-indigo"
              type="button"
              onClick={() => onLogout()}
            >
              Wyloguj się
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
