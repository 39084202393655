import styled from "styled-components";
import { Circles } from "./components/circles";
import { PageView } from "../../components/page-view";
import { useNavigate } from "react-router-dom";
import { FormEvent, useState } from "react";
import { AuthUtils, HttpUtils } from "../../utils";

const Host = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Background = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(150deg, #281483 15%, #8f6ed5 70%, #d782d9 94%);
`;

export const Login = () => {
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const submit = (event: FormEvent) => {
    event.preventDefault();
    HttpUtils.post<{ sid: string }>("/", { password }).then(({ sid }) => {
      console.log("success", sid);
      if (sid) {
        AuthUtils.setUserSid(sid);
        navigate("/");
      }
    });
  };

  return (
    <PageView>
      <Host>
        <Background>
          <Circles />
        </Background>

        <div className="content d-flex justify-content-center">
          <div className="login-form form-validate mt-5">
            <div className="mb-2">
              <img
                src="/img/logo-light.png"
                alt=""
                style={{ maxWidth: "160px" }}
              />
            </div>

            <div className="card">
              <form
                className="card-body py-4"
                onSubmit={(event: FormEvent) => submit(event)}
              >
                <h2 className="font-weight-bold text-center">
                  Panel sędziowski
                </h2>
                <h4 className="text-center mb-3">Zaloguj się</h4>

                <div className="form-group form-group-floating">
                  <div className="position-relative">
                    <input
                      type="password"
                      className="form-control form-control-outline"
                      placeholder=" "
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      data-lpignore={true}
                    />
                    <label className="label-floating">Wpisz hasło</label>
                    <p className="font-weight-light mb-0 mt-2">
                      Twoje hasło zostało ci indywidualnie przydzielone przez
                      skrutinera turnieju. Jeśli nie znasz swojego hasła,
                      skontaktuj się ze skrutinerem.
                    </p>
                  </div>
                </div>

                <div className="form-group mb-0">
                  <button
                    type="submit"
                    className="btn bg-purple-800 text-white btn-block"
                  >
                    Zaloguj się
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Host>
    </PageView>
  );
};
