export const WaitingForSection = () => {
  return (
    <div className="card" style={{ minHeight: "70vh" }}>
      <div className="card-body d-flex flex-column justify-content-center align-items-center">
        <img
          src="/img/awaiting.png"
          alt="awaiting"
          className="mb-4"
          style={{ width: "300px", maxWidth: "calc(100vw - 30px)" }}
        />

        <div className="mb-4 text-center">
          <h5 className="font-weight-semibold">
            Czekaj na otwarcie kategorii...
          </h5>
          {/*<h5 className="font-weight-semibold">Wszystkie kategorie są w tej chwili zamknięte.</h5>*/}
          {/*<p className="mb-4">Czekaj na otwarcie kategorii...</p>*/}
          <p className="mb-0">Wszystkie kategorie są w tej chwili zamknięte.</p>
        </div>

        <button className="btn btn-indigo-100 text-indigo">Wyloguj się</button>
      </div>
    </div>
  );
};
