import { UserInfo } from "../../model";

interface NavbarProps {
  userInfo: UserInfo | undefined;
}

export const Navbar = ({ userInfo }: NavbarProps) => {
  return (
    <div className="navbar navbar-expand-lg navbar-light navbar-static px-2">
      <div className="container-xl">
        <div
          className="navbar-brand"
          style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
        >
          <a href="" className="d-inline-block">
            <img
              src="/img/logo.png"
              className="sidebar-resize-hide"
              style={{ height: "2.5rem" }}
            />
          </a>
        </div>

        <div>
          <a className="navbar-nav-link d-flex align-items-center">
            {/*<a href="" className="navbar-nav-link d-flex align-items-center dropdown-toggle">*/}
            <img
              className="rounded-circle mr-2"
              height="30"
              alt=""
              src="/img/avatar.svg"
            />
            <span>
              {userInfo?.firstName} {userInfo?.lastName}
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};
