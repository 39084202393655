import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { AuthUtils } from "../../utils";

interface ProtectedPageProps {
  children?: ReactNode;
}

export const ProtectedPage = ({ children }: ProtectedPageProps) => {
  const isAuthenticated = AuthUtils.isAuthenticated();

  if (isAuthenticated) {
    return <>{children}</>;
  } else {
    console.error("Not authorized!");
    return <Navigate to="/login" replace />;
  }
};
