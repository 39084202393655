import { UserInfo } from "../../model";

interface CompetitionHeadingProps {
  userInfo: UserInfo | undefined;
  className: string;
}

export const CompetitionHeading = ({
  userInfo,
  className,
}: CompetitionHeadingProps) => {
  return (
    <div className={className}>
      <h3 className="font-weight-semibold mb-1">
        {userInfo?.competition?.name}
      </h3>
      <div>19 - 20 października 2022, {userInfo?.competition?.city}</div>
    </div>
  );
};
