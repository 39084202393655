import { PageView } from "../../components/page-view";
import { useEffect, useState } from "react";
import { AuthUtils, HttpUtils, SocketUtils } from "../../utils";
import { UserInfo } from "../../model";
import { Navbar } from "../../components/navbar";
import { CompetitionHeading } from "../../components/competition-heading";
import { JurySection } from "../../components/jury-section";
import { WaitingForSection } from "../../components/waiting-for-section";
import { Modal } from "react-bootstrap";
import { ForceChangeModal } from "../../components/force-change-modal";
import { SuccessfulSection } from "../../components/successful-section";

type AppState = "WAITING" | "READY" | "FORCE_CHANGE";
type JuryState = "READY" | "CONFIRM" | "DONE";

export const Competition = () => {
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [appState, setAppState] = useState<AppState>("READY");
  const [juryState, setJuryState] = useState<JuryState>("READY");

  useEffect(() => {
    HttpUtils.get<UserInfo>("/user-info").then((info) => {
      setUserInfo(info);
    });

    const jurySid = AuthUtils.getUserSid()!;
    return SocketUtils.connect(jurySid, {
      connect: () => console.log("connected"),
      push_state: ({ state }) => {
        console.log("push-state", state);
        setAppState(state);
      },
    });
  }, []);

  return (
    <PageView>
      <Navbar userInfo={userInfo} />
      {juryState === "READY" && (
        <div className="pb-5">
          <div className="container-xl">
            <CompetitionHeading
              userInfo={userInfo}
              className="col-12 py-3 mt-3"
            />

            {["READY", "FORCE_CHANGE"].includes(appState) && (
              <JurySection onSubmit={() => setJuryState("DONE")} />
            )}
            {appState === "WAITING" && <WaitingForSection />}
          </div>
        </div>
      )}

      {juryState === "DONE" && (
        <div className="pb-5">
          <div className="container-xl pt-3">
            <SuccessfulSection
              onContinue={() => {
                setJuryState("READY");
                setAppState("WAITING");
              }}
              onLogout={() => {}}
            />
          </div>
        </div>
      )}

      <ForceChangeModal
        isOpen={appState === "FORCE_CHANGE"}
        onSubmit={() => setAppState("READY")}
        onLogout={() => {}}
      />
    </PageView>
  );
};
