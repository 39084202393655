interface JurySectionProps {
  onSubmit: () => void;
}

export const JurySection = ({ onSubmit }: JurySectionProps) => {
  return (
    <div className="card">
      <div className="card-body">
        <p className="text-muted">Niedziela - Blok 1</p>
        <h5 className="font-weight-bold">Jazz - Duety - Debiuty (12-15 lat)</h5>

        <table className="table mb-5">
          <thead>
            <tr>
              <th>Numer startowy</th>
              <th>Nazwa</th>
              <th>Tytuł prezentacji</th>
              <th>Miejsce</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="text-right alpha-grey col-2">
                <h5 className="font-weight-bold m-0">10</h5>
              </td>
              <td>
                <p>Anna Nowak</p>
                <p className="mb-0">Karolina Baran</p>
              </td>
              <td>Ocean i wiatr</td>
              <td className="alpha-grey col-2">
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  className="form-control"
                />
              </td>
            </tr>
            <tr>
              <td className="text-right alpha-grey col-2">
                <h5 className="font-weight-bold m-0">10</h5>
              </td>
              <td>
                <p>Anna Nowak</p>
                <p className="mb-0">Karolina Baran</p>
              </td>
              <td>Ocean i wiatr</td>
              <td className="alpha-grey col-2">
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  className="form-control"
                />
              </td>
            </tr>
            <tr>
              <td className="text-right alpha-grey col-2">
                <h5 className="font-weight-bold m-0">10</h5>
              </td>
              <td>
                <p>Anna Nowak</p>
                <p className="mb-0">Karolina Baran</p>
              </td>
              <td>Ocean i wiatr</td>
              <td className="alpha-grey col-2">
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  className="form-control"
                />
              </td>
            </tr>
            <tr>
              <td className="text-right alpha-grey col-2">
                <h5 className="font-weight-bold m-0">10</h5>
              </td>
              <td>
                <p>Anna Nowak</p>
                <p className="mb-0">Karolina Baran</p>
              </td>
              <td>Ocean i wiatr</td>
              <td className="alpha-grey col-2">
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  className="form-control"
                />
              </td>
            </tr>
            <tr>
              <td className="text-right alpha-grey col-2">
                <h5 className="font-weight-bold m-0">10</h5>
              </td>
              <td>
                <p>Anna Nowak</p>
                <p className="mb-0">Karolina Baran</p>
              </td>
              <td>Ocean i wiatr</td>
              <td className="alpha-grey col-2">
                <input
                  type="number"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  className="form-control"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div className="d-flex justify-content-between align-items-center">
          <span>
            Przydzielone noty: <strong>1/7</strong>
          </span>

          <button
            type="button"
            onClick={() => onSubmit()}
            className="btn btn-indigo"
          >
            Wyślij noty
          </button>
        </div>
      </div>
    </div>
  );
};
