import { useLayoutEffect, useState } from "react";
import { Router } from "react-router-dom";
// import { routerActions, useAppDispatch } from './store';
import { Update } from "history";
// import { Update, Location } from 'history';

export const CustomRouter = ({ history, ...props }: any) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  // const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    // const onChange = (location: Location) => dispatch(routerActions.pageLoad({ location }));

    // onChange(history.location);
    history.listen((update: Update) => {
      // onChange(update.location);
      setState(update);
    });
  }, [history]); // dispatch]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};
