import styled from "styled-components";

const styles = [
  {
    bottom: "auto",
    left: "-3%",
    top: "-20px",
    background: "hsla(0, 0%, 100%, 0.1)",
  },
  {
    top: "15%",
    right: "10%",
    background: "hsla(0, 0%, 100%, 0.1)",
  },
  {
    top: "280px",
    right: "5.5%",
    background: "hsla(0, 0%, 100%, 0.3)",
  },
  {
    top: "320px",
    right: "7%",
    background: "hsla(0, 0%, 100%, 0.15)",
  },
  {
    top: "38%",
    right: "auto",
    left: "1%",
    background: "hsla(0, 0%, 100%, 0.05)",
  },
  {
    top: "65%",
    right: "auto",
    left: "35%",
    width: "200px",
    height: "200px",
    background: "hsla(0, 0%, 100%, 0.15)",
  },
  {
    right: "40%",
    bottom: "45%",
    background: "hsla(0, 0%, 100%, 0.04)",
  },
  {
    right: "6%",
    bottom: "100px",
    background: "hsla(0, 0%, 100%, 0.02)",
  },
  {
    right: "5%",
    bottom: "12%",
    background: "hsla(0, 0%, 100%, 0.1)",
  },
  {
    right: "auto",
    bottom: "11%",
    left: "15%",
    background: "hsla(0, 0%, 100%, 0.05)",
  },
];

const Circle = styled.span`
  border-radius: 100px 130px 100px 315px;
  position: absolute;
`;

const Circle50 = styled(Circle)`
  width: 50px;
  height: 50px;
`;

const Circle75 = styled(Circle)`
  width: 75px;
  height: 75px;
`;

const Circle100 = styled(Circle)`
  width: 100px;
  height: 100px;
`;

const Circle150 = styled(Circle)`
  width: 150px;
  height: 150px;
`;

export const Circles = () => {
  const circles = [
    Circle150,
    Circle50,
    Circle50,
    Circle75,
    Circle100,
    Circle75,
    Circle50,
    Circle100,
    Circle50,
    Circle100,
  ];

  return (
    <>
      {circles.map((Circle, index) => (
        <Circle style={styles[index]} key={index} />
      ))}
    </>
  );
};
