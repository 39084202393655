import { ReactNode } from "react";

export const PageView = ({ children }: { children: ReactNode }) => {
  return (
    <div className="page-content">
      <div className="content-wrapper">
        <div className="content-inner pb-5">{children}</div>
      </div>
    </div>
  );
};
